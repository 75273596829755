// Override default variables before the import
$body-bg: #000;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css');

.col-sm-6 {
  max-width: 100vw;
}

.container {
  padding: 0;
}
.container-fluid {
  max-width: 1140px;
}
.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}
html {
  margin: 0px;
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0px;
  background-color: white;
  color: black;
}

body > #root {
  height: 100%;
}

body > #root > #app {
  height: 100%; /* Fallback for browsers that do not support Custom Properties */
  font-family: ‘Helvetica’, ‘Arial’, sans-serif;
  font-size: 16px;
}
ul {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
p {
  display: block;
  margin-block-start: 0px;
  margin-block-end: inherit;
  margin-inline-start: 0px;
  margin-inline-end: inherit;
}
.no-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.no-match {
  color: #9e9e9e;
  font-style: italic;
  font-size: 24px;
  height: 30px;
}
.allow-overflow {
  overflow: unset;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.navigation-arrow {
  margin-right: 5px;
  padding: 2px 5px 0px 5px;
  margin-left: -5px;
  width: 30px;
  height: 25px;
  cursor: pointer;
}
.thinner {
  -webkit-text-stroke: 3px #dddddd;
}
.error-display-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.oops {
  margin-bottom: 20px;
}

.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}

.col-centered {
  float: none;
  margin: 0 auto;
  width: 570px;
}

.row-header {
  background-color: #dddddd;
  flex-direction: column-reverse;
  position: relative;
}
.row-subheader {
  background-color: #555555;
  font-size: 24px;
  color: white;
  flex-direction: column;
}
.header-text {
  margin-bottom: 10px;
  font-size: 18px;
  display: inherit;
  cursor: pointer;
  width: fit-content;
}
.logout-container {
  padding-bottom: 5px;
  border-bottom: 2px white solid;
  cursor: pointer;
}
.person-icon {
  width: 60px;
  height: 60px;
  float: left;
}
.header-logout {
  margin-top: 30px;
  font-size: 20px;
  padding: 0;
  border: none;
  background-color: #dddddd;
}
.logout-icon {
  position: absolute;
  right: 26px;
  margin-top: 4px;
  height: 21px;
}
.header-image {
  width: fit-content;
  margin-bottom: 15px;
  padding-top: 5px;
}
.subheader-superMessage {
  font-size: 14px;
  height: 10px;
  margin-top: -4px;
  margin-bottom: 5px;
}
.subheader-message {
  font-size: 24px;
  height: 30px;
}
.subheader-right {
  margin-top: 12px;
}
.search-row {
  flex-direction: column-reverse;
}
.search-filter {
  margin-bottom: 10px;
  padding: 0;
  color: #9e9e9e;
  width: 100%;
  font-size: 16px;
  border: none;
}
.input-field {
  margin-bottom: 10px;
  padding: 0;
  color: black;
  width: 100%;
  font-size: 16px;
  border: none;
}
.height-min-60 {
  height: 8.33333vh;
  min-height: 60px;
}
.height-60 {
  height: 60px;
}

.padded-list-item {
  padding: 10px 30px 5px 30px;
}
.margin-list-item {
  margin: 0px 15px 0px 15px;
  padding-top: 10px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #9e9e9e;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #9e9e9e;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #9e9e9e;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9e9e9e;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9e9e9e;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #9e9e9e;
}

.error-message {
  color: red;
}
.spinner-overlay {
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4000;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
  transform-origin: 50% 62%;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  z-index: 5000;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.no-bottom-border {
  border-bottom: none;
}
.restrict-subheader-width {
  width: 70%;
}
.keep-fixed {
  position: fixed;
  bottom: 0;
}
.header-menu-icon {
  height: 30px;
  width: 30px;
}
